// Modules exported here will be included in bundle.js
export function showCopiedMessage(button, eventType, value) {
  if (eventType == 'copyResultText' || eventType == 'copyLink') {
    navigator.clipboard.writeText(value);
  } else {
    var message = {"eventType": eventType, "value": value};
    parent.postMessage(message, "*");
  }

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
};